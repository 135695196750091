import * as React from "react";
import { Helmet } from "react-helmet";

const NotFoundPage = () => {
  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Consult FGC | Digital Marketing Agency | Website Design & Development</title>

        <meta
          name="description"
          content="Consult FGC is a boutique digital marketing agency specializing in SEM, SEO, website design & development, and geofencing marketing & advertising."
        />
        <link
          rel="icon"
          href="https://consultfgc.netlify.app/static/ConsultFGC_Logo_footer-8b0c6cdcc10d6cc56b61a7b78ed851b7.png"
        />
      </Helmet>
    <div class="bg-white mt-1/2 px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <img
        class="mx-auto w-1/5"
        src="https://imgix.cosmicjs.com/fc7453b0-b6fb-11ea-b20f-f350ffc5594c-ConsultFGCLogosq.jpg"
        alt="logo"
      />
      <div class="max-w-max mx-auto">
        <main class="sm:flex">
          <p class="text-4xl font-extrabold text-blue-800 sm:text-6xl">404</p>
          <div class="sm:ml-6">
            <div class="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 class="text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page not found
              </h1>
              <p class="mt-1 text-base text-gray-500">
                Please check the URL in the address bar and try again.
              </p>
            </div>
            <div class="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <a
                href="/"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-cyan-500 hover:opacity-80 focus:outline-none"
              >
                {" "}
                Go back home{" "}
              </a>
              <a
                href="/contact-us"
                class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-blue-700 bg-blue-100 hover:bg-blue-200 focus:outline-none"
              >
                {" "}
                Contact support{" "}
              </a>
            </div>
          </div>
        </main>
      </div>
    </div>
    </main>
  );
};

export default NotFoundPage;
